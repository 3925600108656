  ' use strict';
  var dependencies = [
  'ui.router','ngTableExport','xeditable',
  'ngTable','ui.bootstrap','directives','angular-loading-bar',
  'ngMessages','uiNotify','datatables','ngCookies','angular-growl','n3-pie-chart','ngResource','tableSort',
  'chart.js',
  'mgcrea.ngStrap','infinite-scroll','ngSanitize','ngFileUpload', 'ng-bs3-datepicker','ngJsonExportExcel','textAngular', 'nvd3',  
  ];
  var main = angular.module('main', dependencies);
  main.config(function($stateProvider, $urlRouterProvider, growlProvider,$locationProvider) {



    // $locationProvider.html5Mode(true);
    $stateProvider


    .state('home', {
      url: "/home",
      templateUrl : 'tpls/home/home.html',
    })


    // .state('/', {
    //   url: "/home",
    //   templateUrl : 'tpls/home/dashboard.html',
    // })

    .state('/profile', {
      url: "/profile",
      templateUrl : 'tpls/home/profile.html',
    })

    

    .state('budgets', {
      url: "/budgets",
      templateUrl : 'tpls/home/budgets.html',
    })


    .state('settings', {
      url: "/settings",
      templateUrl : 'tpls/home/settings.html',
    })

    .state('formupload', {
      url: "/formupload",
      templateUrl : 'tpls/home/formupload.html',
    })


    .state('reports', {
      url: "/reports",
      templateUrl : 'tpls/home/reports.html',
    })


    .state('objectives', {
      url: "/objectives",
      templateUrl : 'tpls/home/objectives.html',
    })



    .state('tasks', {
      url: "/tasks/:objectiveId",
      templateUrl : 'tpls/home/tasks.html',
    })

    .state('reviews', {
      url: "/reviews/:objectiveId",
      templateUrl : 'tpls/home/reviews.html',
    })

    /* messaging*/

    .state('/404', {
      url: "/404",
      templateUrl : 'tpls/home.html',
    });
    $urlRouterProvider.otherwise('/home');
    growlProvider.globalTimeToLive(3000);
    growlProvider.onlyUniqueMessages(false);
    
  });

  main.run(function(editableOptions, editableThemes) {
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-sm';
    editableOptions.theme = 'bs3';
  });

//   main.config(function($locationProvider, $urlRouterProvider) {
//   $locationProvider.html5Mode(true);
//  $urlRouterProvider
//     .when('/', {
//       templateUrl: 'tpls/home/home.html', 
//       controller: 'homeController'
//     })
//     .when('/objectives', {
//       templateUrl: 'tpls/home/objectives.html', 
//       controller:  'objectivesController'
//     })
//     .when('/budgets', {
//       templateUrl: 'tpls/home/budgets.html', 
//       controller:  'budgetsController'
//     })
//     .otherwise({ redirectTo: '/' });
// });
