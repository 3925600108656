main.service('ObjectivesService', function(modalService, appFactory, notify, $modal, $http) {
    'use strict';
    var Objective = this;
    Objective.getObjectives = function() {
        return $http({
            url: 'index.php/getObjectives/',
        });
    };
    // Objective.getObjectives = function() {
    //     return $http({
    //         url: 'index.php/getObjectives/',
    //     });
    // };
    Objective.getObjectivesPDF = function(objectives,deptId,yearId) {
        return $http({
            url: 'index.php/getPDF/',
            params: {
                'data': objectives,"deptId":deptId,"yearId":yearId
            }
        });
    };
    Objective.getObjectivesEXCEL = function(objectives,deptId,yearId) {
        return $http({
            url: 'index.php/getExcel/xls/',
            params: {
                'data': objectives,"deptId":deptId,"yearId":yearId 
            }
        });
    };
    
});


