main.service('mailService', function (modalService, appFactory, notify, $modal, $http) {

  'use strict';

  var Mail = this;

  Mail.getMails = function() {
    return $http({
      url : 'index.php/getMails/',
    });
  };

});