'use strict';
main.controller('reportsController', ['$window', 'registerService', 'growl', '$interval', '$rootScope', 'ReportsService', '$scope', '$http', 'Upload', '$timeout', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state',
    function($window, registerService, growl, $interval, $rootScope, ReportsService, $scope, $http, Upload, $timeout, appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state) {
        ReportsService.getReports().success(function(data, status) {
            $scope.departments = data.departments;
            $scope.years = data.years;
            $scope.reports = data.reports;
            $scope.users = data.users;
            $rootScope.role = data.role;
        });
        
        $scope.reports = restService.intiateNgTable(ReportsService.getReports(), $scope);
        $scope.searchFish   = ''; 
        $scope.searchObjective = function(searchedObjective) {
            $scope.searchedTerm = null;
            if (searchedObjective.dpt_name == 'All Departments') {
                searchedObjective = undefined;
            }
            console.log(searchedObjective);
            $scope.searchedTerm = searchedObjective;
            $rootScope.data.reload();
        }
        
        $scope.today = new Date();

        var tick = function() {
            $scope.clock = Date.now();
        }

        tick();
        $interval(tick, 1000);
        $scope.toggle = function(modalstate, mailID, modelId) {
            $scope.modalstate = modalstate;
            switch (modalstate) {
                case 'add':
                $scope.form_title = "Upload Form";
                break;
            }
            $(modelId).modal('show');
        }

        // var i = 100;
        // var counterBack = setInterval(function(){
        //   i--;
        //   if (i > 0){
        //     $('.progress-bar').css('width', i+'%');
        //   } else {
        //     clearInterval(counterBack);
        //   }
        // }, 1000);
        //  $scope.downloadUrl = response.data.fileName;
        // var fileDownloadLink = document.getElementById("fileDownloadLink");
        // fileDownloadLink.target = "_blank"; // Opens the file in a new window.
        // fileDownloadLink.click();


        $scope.edit = function(id) {
            if (modalstate === 'edit') {
                url += "/" + id;
            }
            dataFactory.httpRequest('reports/' + id + '/edit').then(function(data) {
                console.log(data);
                $scope.form_title = data;
            });
        }
        $scope.saveEdit = function() {
            dataFactory.httpRequest('reports/' + $scope.form_title.id, 'PUT', {}, $scope.form_title).then(function(data) {
                $(".modal").modal("hide");
                $scope.data = apiModifyTable($scope.data, data.id, data);
            });
        }

        $scope.deleteItem = function(id, modelname) {
            restService.deleteItem(id, modelname)
        }

        $scope.updateRow = function(id, modalname, data) {
            restService.updateRow(id, modalname, data);
            location.reload();
        }
        
        $scope.submit = function(formfile, report) {
            if (formfile) {
                $scope.file = formfile;
                $scope.upload($scope.file, report);
            }
        };
        $scope.downloadFile = function(filename) {
            $window.location.href = 'index.php/downloadFile?fileName=' + filename;
        }
        $scope.upload = function(file, report) {
            Upload.upload({
                url: 'upload',
                data: {
                    file: file,
                    'report': report
                },
            }).then(function(resp) {
                location.reload();
            }, function(resp) {
                console.log('Error status: ' + resp.status);
            }, function(evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
        $scope.showSuccess = function(success, modalstate, objId, action) {
            growl.success('Report  successfully Uploaded.');
        }
        $scope.fileHref = 'public/reports';
    }
    ]);