'use strict';
main.controller('settingsController', ['$rootScope','$interval','departmentsService','growl','statusService','mailService','currencyServices', 'registerService','yearsService', '$scope', '$http','appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state',
    function($rootScope,$interval,departmentsService,growl,statusService,mailService,currencyServices,registerService,yearsService, $scope, $http,appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state) {

        registerService.getUsers().success(function(data, status) {
            $rootScope.departments = data.departments;
            $rootScope.role = data.role;
            $rootScope.name = data.name;
            $rootScope.email = data.email;
            $rootScope.name = data.name;
            $rootScope.info = data.info;
            $rootScope.status = data.status;
        // console.log(data.role);
    });
        $scope.users = restService.intiateNgTable(registerService.getUsers(), $scope);
        $scope.searchUser = function(searchedUser) {
            $scope.searchedTerm = null;
            $scope.searchedTerm = searchedUser;
            $scope.users.reload();
            $scope.tableParams.page(1);
        }

        registerService.getInactive().success(function(data) {   
            $scope.data = data.data;    
        });

        main.directive("matchPassword", function () {
            return {
                require: "ngModel",
                scope: {
                    otherModelValue: "=matchPassword"
                },
                link: function(scope, element, attributes, ngModel) {

                    ngModel.$validators.matchPassword = function(modelValue) {
                        return modelValue == scope.otherModelValue;
                    };

                    scope.$watch("otherModelValue", function() {
                        ngModel.$validate();
                    });
                }
            };
        });

        $scope.usersStatuses = [{
            'text': 'Active',
            'value': 'Active'
        }, {
            'text': 'Inactive',
            'value': 'Inactive'
        }];

        $scope.usersRoles = [{
            'text': 'User',
            'value': 'User'
        }, {
            'text': 'Admin',
            'value': 'Admin'
        }];
        //disply modalform.
        $scope.toggle = function(modalstate, id) {
            $scope.modalstate = modalstate;
            switch (modalstate) {
                case 'add':
                $scope.form_title = "Add New User";
                break;
            }
            $('#myModal').modal('show');
        }
            //save new record & update existing record
            $rootScope.saveU = function(modalstate, id,action) {
                var url = "users";
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param($scope.user),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function(response) {
                    console.log(action)
                    if (action == 'close') {
                        location.reload();
                    }else{
                        $scope.user.name ="";
                        $scope.user.role ="";
                        $scope.user.status ="";
                        $scope.user.deptId ="";
                        $scope.user.password ="";
                        $scope.user.confirm_password ="";
                        $scope.user.email ="";
                        console.log($scope.user)
                    }
                }).error(function(response) {
                    console.log(response);
                    alert('An error has occured. Please check the log for details');
                });
            }
            $scope.edit = function(id) {
                if (modalstate === 'edit') {
                    url += "/" + id;
                }
                dataFactory.httpRequest('users/' + id + '/edit').then(function(data) {
                    console.log(data);
                    $scope.form_title = data;
                });
            }
            $scope.saveEdit = function() {
                dataFactory.httpRequest('users/' + $scope.form_title.id, 'PUT', {}, $scope.form_title).then(function(data) {
                    $(".modal").modal("hide");
                    $scope.data = apiModifyTable($scope.data, data.id, data);
                });
            }
            $scope.deleteItem = function(id, modelname) {
                location.reload();
                restService.deleteItem(id, modelname)

            }
            $scope.updateRow = function(id, modalname, data) {
                restService.updateRow(id, modalname, data);
                location.reload();
            }


            $scope.departmentParams = restService.intiateNgTable(departmentsService.getDepartments(), $scope);

            $scope.searchDepartment = function(searchedDepartment) {
                $scope.searchedTerm = null;
                $scope.searchedTerm = searchedDepartment;
                $scope.departmentParams.reload();
            } 
            //disply modalform.
            $scope.toggle = function(modalstate, deptId, modelId) {
                $scope.modalstate = modalstate;
                switch (modalstate) {
                    case 'add':
                    $scope.form_title = "Add New Department";
                    break;
                }
                $(modelId).modal('show');
            }


            //save new record & update existing record
            $rootScope.saveD = function(modalstate, deptId, action) {
                var url = "departments";
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param($scope.department),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function(response) {
                    console.log(action)
                    if (action == 'close') {
                        location.reload();
                    }else{
                        $scope.department.dpt_name ="";
                        
                        console.log($scope.department)
                    }
                    // location.reload();
                }).error(function(response) {
                    console.log(response);
                    alert('An error has occured. Please check the log for details');
                });
            }

            $scope.toggle = function(modalstate, mailID, modelId) {
                $scope.modalstate = modalstate;
                switch (modalstate) {
                    case 'add':
                    $scope.form_title = "Mail Area";
                    break;
                }
                $(modelId).modal('show');
            }

            $scope.edit = function(deptId) {
                if (modalstate === 'edit') {
                    url += "/" + deptId;
                }
                dataFactory.httpRequest('departments/' + deptId + '/edit').then(function(data) {
                    console.log(data);
                    $scope.form_title = data;
                });
            }

            $scope.saveEdit = function() {
                dataFactory.httpRequest('departments/' + $scope.form_title.deptId, 'PUT', {}, $scope.form_title).then(function(data) {
                    $(".modal").modal("hide");
                    $scope.data = apiModifyTable($scope.data, data.deptId, data);
                });
            }

            $scope.deleteItem = function(id, modelname) {
                restService.deleteItem(id, modelname)
            }
            $scope.updateRow = function(id, modalname, data) {
                restService.updateRow(id, modalname, data);
            }





            $scope.years= restService.intiateNgTable(yearsService.getYears(), $scope);

            $scope.searchYear = function(searchedYear) {
                $scope.searchedTerm = null;
                $scope.searchedTerm = searchedYear;
                $scope.years.reload();
            } 
            //disply modalform.
            $scope.toggle = function(modalstate, yearId, modelId) {
                $scope.modalstate = modalstate;
                switch (modalstate) {
                    case 'add':
                    $scope.form_title = "Add Years";
                    break;
                }
                $(modelId).modal('show');
            }

            //save new record & update existing record
            $rootScope.saveY = function(modalstate, yearId,action) {

                var url = "years";
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param($scope.year),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function(response) {
                    console.log(action)
                    if (action == 'close') {
                        location.reload();
                    }else{
                        $scope.year.yearc ="";
                        console.log($scope.year)
                    }

                }).error(function(response) {
                    console.log(response);
                    alert('An error has occured. Please check the log for details');
                });
            }

            $scope.edit = function(yearId) {
                if (modalstate === 'edit') {
                    url += "/" + yearId;
                }
                dataFactory.httpRequest('years/' + yearId + '/edit').then(function(data) {
                    console.log(data);
                    $scope.form_title = data;
                });
            }

            $scope.saveEdit = function() {
                dataFactory.httpRequest('years/' + $scope.form_title.yearId, 'PUT', {}, $scope.form_title).then(function(data) {
                    $(".modal").modal("hide");
                    $scope.data = apiModifyTable($scope.data, data.yearId, data);
                });
            }

            $scope.deleteItem = function(id, modelname) {
                restService.deleteItem(id, modelname)
            }
            $scope.updateRow = function(id, modalname, data) {
                restService.updateRow(id, modalname, data);
            }


            $scope.statuses= restService.intiateNgTable(statusService.getStatuses(), $scope);

            $scope.searchStatus = function(searchedStatus) {
                $scope.searchedTerm = null;
                $scope.searchedTerm = searchedStatus;
                $scope.statuses.reload();
            } 
            //disply modalform.
            $scope.toggle = function(modalstate, statId, modelId) {
                $scope.modalstate = modalstate;
                switch (modalstate) {
                    case 'add':
                    $scope.form_title = "Add Statuses";
                    break;
                }
                $(modelId).modal('show');
            }

            //save new record & update existing record
            $rootScope.saveS = function(modalstate, statId,action) {
                var url = "statuses";
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param($scope.status),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function(response) {
                    // location.reload();
                    console.log(action)
                    if (action == 'close') {
                        location.reload();
                    }else{
                        $scope.status.stat ="";
                        console.log($scope.status)
                    }
                }).error(function(response) {
                    console.log(response);
                    alert('An error has occured. Please check the log for details');
                });
            }

            $scope.edit = function(statId) {
                if (modalstate === 'edit') {
                    url += "/" + statId;
                }
                dataFactory.httpRequest('statuses/' + statId + '/edit').then(function(data) {
                    console.log(data);
                    $scope.form_title = data;
                });
            }

            $scope.saveEdit = function() {
                dataFactory.httpRequest('statuses/' + $scope.form_title.statId, 'PUT', {}, $scope.form_title).then(function(data) {
                    $(".modal").modal("hide");
                    $scope.data = apiModifyTable($scope.data, data.statId, data);
                });
            }

            $scope.deleteItem = function(id, modelname) {
                restService.deleteItem(id, modelname)
            }
            $scope.updateRow = function(id, modalname, data) {
                restService.updateRow(id, modalname, data);
            }



            $scope.currencies= restService.intiateNgTable(currencyServices.getCurrencies(), $scope);

            $scope.searchCurrency = function(searchedCurrency) {
                $scope.searchedTerm = null;
                $scope.searchedTerm = searchedCurrency;
                $scope.currencies.reload();
            } 
            //disply modalform.
            $scope.toggle = function(modalstate, currencyId, modelId) {
                $scope.modalstate = modalstate;
                switch (modalstate) {
                    case 'add':
                    $scope.form_title = "Add currency";
                    break;
                }
                $(modelId).modal('show');
            }

            //save new record & update existing record
            $rootScope.saveC = function(modalstate, currencyId, action) {

                var url = "currencies";
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param($scope.currency),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function(response) {
                    console.log(action)
                    if (action == 'close') {
                        location.reload();
                    }else{
                        $scope.currency.code ="";
                        $scope.currency.type ="";
                        $scope.currency.cname ="";
                        $scope.currency.symbol ="";
                        console.log($scope.currency)
                    }
                    // location.reload();
                }).error(function(response) {
                    console.log(response);
                    alert('An error has occured. Please check the log for details');
                });
            }

            $scope.edit = function(currencyId) {
                if (modalstate === 'edit') {
                    url += "/" + currencyId;
                }
                dataFactory.httpRequest('currencies/' + currencyId + '/edit').then(function(data) {
                    console.log(data);
                    $scope.form_title = data;
                });
            }

            $scope.saveEdit = function() {
                dataFactory.httpRequest('currencies/' + $scope.form_title.currencyId, 'PUT', {}, $scope.form_title).then(function(data) {
                    $(".modal").modal("hide");
                    $scope.data = apiModifyTable($scope.data, data.currencyId, data);
                });
            }

            $scope.deleteItem = function(id, modelname) {
                restService.deleteItem(id, modelname)
            }
            $scope.updateRow = function(id, modalname, data) {
                restService.updateRow(id, modalname, data);
            }

            mailService.getMails().success(function(data,status) {
             $rootScope.users = data.users;


         });

            $rootScope.mails = restService.intiateNgTable(mailService.getMails(), $rootScope);


            $scope.today = new Date();
            var tick = function() {
             $scope.clock = Date.now();
         }
         tick();
         $interval(tick, 1000);


         $scope.update = function(email,password,confirmpassword) {  
            appFactory.update(email,password,confirmpassword).success(function(data) {
               notify({
                message:data.message,
                template: 'tpls/notify/success.html',
                position:'center'
            });

           }).error(function(message) {
            console.log(message);
            alert('An error has occured. Please check the log for details');
        });

       }; 




   }






   ]);