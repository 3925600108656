

main.controller('SuppliersController', ['$rootScope', '$scope', 'appFactory','$location', 'SuppliersService','restService', '$modal', '$log', 'notify', 'modalService', 'ngTableParams','$filter',

	function($rootScope, $scope, appFactory, $location, SuppliersService, restService, $modal, $log, notify, modalService, ngTableParams,$filter) {

		'use strict';

		$scope.Title = "Suppliers";

		$scope.module_root = "Home";
		$scope.module_name = "Suppliers";
		$scope.submod_name = "Dashboard";

		$scope.module_url  = "suppliers";
		$scope.submod_url  = "suppliers";

		$scope.SuppliersList = function () {
			$scope.printableSection = "printSuppliersList";
			SuppliersService.getList().success(function(data, status) {
				$scope.Supplier = data.list;
			}).error(function(data, status) {
				alert('something went wrong');
			});
		};



		$scope.SupplierProfile = function (formname, windowClass, id) { 
			var type = 'supplier';
			SuppliersService.SupplierProfile(formname, windowClass, id, type);
		}

		$scope.GetSupplierOrders = function(type, party_id) {
			// console.log(type, party_id)
			SuppliersService.GetOrders(type, party_id).success(function(data, status) {
				$scope.Orders = data.list;
			}).error(function(data, status) {
				alert('something went wrong');
			});
		}


		$scope.GetVendorPurchases = function(party_id) { 
			console.log(party_id)
			SuppliersService.GetVendorPurchases(party_id).success(function(data, status) {
				$scope.VendorPurchases = data.list;

				console.log($scope.VendorPurchases)

			}).error(function(data, status) {
				alert('something went wrong');
			});
		}


		$scope.cancel = function () {
			SuppliersService.cancel();
		};

		$scope.openSuppliersForm = function (formname, windowClass, modelname, Iterator) {
			SuppliersService.openForm(formname, windowClass, modelname, Iterator);
		};

		$scope.postList = function (dict, modelname, valid) {
			SuppliersService.postList(dict, modelname, valid);
		};




		var ids = [];
		var indexes = [];
		$scope.deleteIndex = true;
		$scope.pickList = function ($index, rows, modelname) {
			if (rows.checkId) {
				if (rows) {
					$scope.deleteIndex = false;
				}
				indexes.push($index);
				ids.push(rows.id) ;
			}else{
				var index = ids.indexOf(rows.id);
				ids.splice(index, 1);

				var index_item = indexes.indexOf($index);
				indexes.splice(index_item, 1);
				if (indexes.length === 0) {
					$scope.deleteIndex = true;
				}
			}
		};

		$scope.deleteList = function (modelname, Iterator) {
			restService.deleteList(indexes, ids, modelname, Iterator);
			ids = [];
			indexes = [];
		};




	}]);
