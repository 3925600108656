main.service('statusService', function (modalService, appFactory, notify, $modal, $http) {

  'use strict';

  var Status = this;

  Status.getStatuses = function() {
    return $http({
      url : 'index.php/getStatuses/',
    });
  };

});