'use strict';
main.service('restService', function(modalService, appFactory, notify, $modal, $http,ngTableParams, $filter, $state, $stateParams) {
    var rest = this;
    rest.getItem = function(id, modelname) {
        return $http({
            method: 'GET',
            url: 'index.php/getItem/',
            params: {
                'id': id,
                'modelname': modelname
            }
        });
    }
    rest.getList = function(modelname) {
        return $http({
            method: 'GET',
            url: 'index.php/getList/',
            params: {
                'modelname': modelname
            }
        });
    }
    rest.getItemsByTableName = function(tablename) {
        return $http({
            method: 'GET',
            url: 'index.php/getItemsByTableName/',
            params: {
                'tablename': tablename
            }
        });
    }

    rest.openForm = function(formname, windowClass, modelname, Iterator) {
        var modalInstance = $modal.open({
            templateUrl: formname,
            windowClass: windowClass,
            keyboard: false,
            backdrop: 'static',
            resolve: {
                tablename: function() {
                    return Iterator;
                },
            },
            controller: function($scope, $modalInstance, tablename, notify) {
                rest.postList = function(dict, modelname, valid) {
                    if (valid == 1) {
                        appFactory.postList(dict, modelname).success(function(data, status) {
                            tablename.push(data.insertedData);
                            $modalInstance.close();
                            notify({
                                message: data.message,
                                template: 'tpls/notify/success.html',
                                position: 'center'
                            });
                        }).error(function(error, status) {
                            notify({
                                message: error.message,
                                template: 'tpls/notify/error.html',
                                position: 'center'
                            });
                        });
                    }
                };
                rest.cancel = function() {
                    $modalInstance.dismiss('cancel');
                };
            }
        });
    };
    rest.updateRow = function(id, modelname, data) {  
        $http({
            'method':'PUT','url':'index.php/updateRow', params:{'id':id,'data':data, 'modelname':modelname},
        }).success(function(data,status){ 
            notify({
                message: data.message, 
                template:'tpls/notify/success.html',
                position:'center'
            });
        }).error(function(data, status) {
            notify({
                message: "Something went wrong on updating "+modelname+". Please try Again!",
                template:'tpls/notify/error.html',
                position:'center'
            });
        });
    };
    rest.updateColumn = function(data, modelname) {
        appFactory.updateColumn(data, modelname).success(function(data, status) {
            notify({
                message: data.message,
                template: 'tpls/notify/success.html',
                position: 'center'
            });
        }).error(function(data, status) {
            notify({
                message: "Something went wrong on updating " + modelname + ". Please try Again!",
                template: 'tpls/notify/error.html',
                position: 'center'
            });
        });
    };
    rest.deleteItem = function(id, modelname) {
        var modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete ' + modelname + ' ',
            headerText: 'Delete ' + modelname + ' ',
            bodyText: 'Are you sure you want to delete this ' + modelname + ' ?',
        };
        modalService.showModal({}, modalOptions).then(function(result) {
            appFactory.deleteItem(id, modelname).success(function(data, status, headers, config) {
                $state.go($state.current, {}, {reload: true});
                notify({
                    message: data.message,
                    template: 'tpls/notify/success.html',
                    position: 'center'
                });
            }).error(function(data, status, headers, config) {
                notify({
                    message: "Something went wrong on deleting " + modelname + ". Please try Again!",
                    template: 'tpls/notify/error.html',
                    position: 'center'
                });
            });
        });
    };

    rest.intiateNgTable = function(serviceMethod, $scope) {

        return new ngTableParams({
            page: 1,
            count: 10,
            sorting: {yearc: "desc" },
        }, {
            total: 0,
            getData: function($defer, params) {
                serviceMethod.success(function(data) {
                    params.total($filter('filter')(data.data, $scope.searchedTerm).length);
                    var filteredData = $filter('filter')(data.data, $scope.searchedTerm);
                    var orderedData = params.sorting() ? $filter('orderBy')(filteredData, params.orderBy()) : filteredData;
                    $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                }).error(function(data, status) {
                    notify({
                        message: data.message,
                        template: 'tpls/notify/error.html',
                        position: 'center'
                    });
                });
            },
            // $scope: $scope
        });
    }
    rest.deleteList = function(indexes, ids, modelname, Iterator) {
        var modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete ' + modelname + ' ?',
            headerText: 'Delete ' + modelname + ' ',
            bodyText: 'Are you sure you want to delete selected ' + modelname + ' ?',
        };
        modalService.showModal({}, modalOptions).then(function(result) {
            appFactory.deleteList(ids, modelname).success(function(data, status, headers, config) {
                Iterator.splice(indexes, indexes.length);
                notify({
                    message: data.message,
                    template: 'tpls/notify/success.html',
                    position: 'center'
                });
            }).error(function(data, status, headers, config) {
                notify({
                    message: "Something went wrong on deleting " + modelname + ". Please try Again!",
                    template: 'tpls/notify/error.html',
                    position: 'center'
                });
            });
        });
    };
});