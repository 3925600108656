main.service('UserSettingsService', function (ModalApiService, appFactory, notify, $modal, $http) {

  'use strict';  
  var User = this;

  User.GetEmployeeList = function() {
    return $http({
      method : 'GET',
      url : 'index.php/GetEmployeeList/', 
    });
  }


  User.GetOwnUserProfile = function() {
    return $http({
      method : 'GET',
      url : 'index.php/GetOwnUserProfile/', 
    });
  }


  User.OpenEmployeeProfile = function (tpltname, windowClass, id){  
   var modalInstance = $modal.open({
    templateUrl: tpltname, windowClass: windowClass,
    keyboard: false, backdrop: 'static',
    resolve: {
      id: function () { return id; },
    },
    controller: function ($scope, $rootScope, $modalInstance, id, notify) {   
      $http({
        'method' : 'GET', 'url' : 'index.php/getEmployee/', params : { 'id' : id }
      }).success(function(data, status ){ 
        $scope.Employee = data.item;
      });   

      User.closeProfile = function () {
        $modalInstance.dismiss('cancel');
      };

    }
  }); 
 }; 



 User.OpenChangePassword = function (tpltname, windowClass){  
   var modalInstance = $modal.open({
    templateUrl: tpltname, windowClass: windowClass,
    keyboard: false, backdrop: 'static', 
    controller: function ($scope, $rootScope, $modalInstance, notify) { 
      User.ChangePassword = function(formdata) {
       $http({
        'method' : 'POST', 'url' : 'index.php/ChangePassword',data:{formdata:formdata}
      }).success(function(data, status ){ 
        $modalInstance.close();
        notify({
          message: data.message,
          template:'tpls/notify/success.html',
          position:'center'
        });
      }).error(function(data, status, headers, config) {
        notify({
          message: data.message,
          template:'tpls/notify/error.html',
          position:'center'
        });
      });   
    }

    User.closePassModel = function () {
      $modalInstance.dismiss('cancel');
    };

  }
}); 
 }; 


 User.ResetPassword = function(id) {
  var modalOptions = {
    closeButtonText: 'Cancel',
    actionButtonText: 'Reset password ?',
    headerText: 'Reset password ',
    bodyText: 'Are you sure you want to reset selected user password ?',
  };

  ModalApiService.showModal({}, modalOptions).then(function (result) {  
    $http({
      'method':'POST','url':'index.php/ResetPassword', data:{'id':id},
    }).success(function(data, status, headers, config) {  
      notify({
        message: data.message,
        template:'tpls/notify/success.html',
        position:'center'
      });
    }).error(function(data, status, headers, config) {
      notify({
        message: "Something went wrong on password reset, Please try Again!",
        template:'tpls/notify/error.html',
        position:'center'
      });
    }); 
  });
};


User.OpenDesignationDetails = function (tpltname, windowClass, id){  
 var modalInstance = $modal.open({
  templateUrl: tpltname, windowClass: windowClass,
  keyboard: false, backdrop: 'static',
  resolve: {
    id: function () { return id; },
  },
  controller: function ($scope, $rootScope, $modalInstance, id, notify) {   
    $http({
      'method' : 'GET', 'url' : 'index.php/getDesignationEmployees/', params : { 'id' : id }
    }).success(function(data, status ){ 
      $scope.Designation = data.item;
      $scope.Employees   = data.list;
    });   

    User.closeDesignation = function () {
      $modalInstance.dismiss('cancel');
    };

  }
}); 
}; 


User.OpenDepartmentDetails = function (tpltname, windowClass, id){  
 var modalInstance = $modal.open({
  templateUrl: tpltname, windowClass: windowClass,
  keyboard: false, backdrop: 'static',
  resolve: {
    id: function () { return id; },
  },
  controller: function ($scope, $rootScope, $modalInstance, id, notify) {   
    $http({
      'method' : 'GET', 'url' : 'index.php/getDepartmentEmployees/', params : { 'id' : id }
    }).success(function(data, status ){ 
      $scope.Designation = data.item;
      $scope.Employees   = data.list;
    });   

    User.closeDesignation = function () {
      $modalInstance.dismiss('cancel');
    };

  }
}); 
}; 





User.openUsersForm = function (formname, windowClass, Iterator){  
  var modalInstance = $modal.open({
    templateUrl: formname, windowClass: windowClass,
    keyboard: false, backdrop: 'static',
    resolve: {
      Items: function () { return Iterator; }, 
    },
    controller: function ($scope, $rootScope, $modalInstance, Items, notify) {  
      User.saveUsers = function (formdata) {  
        $http({
          'method':'POST','url':'index.php/storeUser', data:{'formdata':formdata},
        }).success(function(data,status){   
          Items.push(data.insertedData);  
          $modalInstance.close();
          notify({
            message: data.message, 
            template:'tpls/notify/success.html',
            position:'center'
          });
        }).error(function(error,status){
          notify({
            message: error.message, 
            template:'tpls/notify/error.html',
            position:'center'
          });
        });  
      };  

      User.closeForm = function () {
        $modalInstance.dismiss('cancel');
      };

    }
  }); 
}; 














});