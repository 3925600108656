'use strict';

main.controller('accountingSettingsController', ['$rootScope', '$scope', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'restService', 'accountingSettingsService', '$stateParams','Paginator', '$filter', 
	
	function($rootScope, $scope, appFactory, $modal, $log, notify, modalService,  restService, accountingSettingsService, $stateParams, Paginator,  $filter) {
		$scope.Title = "Settings";   

		$rootScope.Transactioncodes = function() {
			$scope.Tabname = 'Transaction codes';
			accountingSettingsService.getTransactionCodes('Transactioncode').success(
				function (data) {
					$rootScope.Transactioncode = data.list;   
				});
		}

		$scope.postList = function (dict, modelname, valid, Iterator) {  
			restService.postList(dict, modelname, valid, Iterator);
		}



		$scope.transactionTypes = function () {  
			$scope.transaction_types = [	
			{ value: "Payment", text: "Payment"},
			{ value: "Receipt", text: "Sales Receipt"},
			{ value: "PettyCash", text: "Petty Cash"},
			{ value: "Deposit", text: "Deposit"},
			{ value: "JouranlEntry", text: "Jouranl Entry"},
			{ value: "JournalProper", text: "Journal Proper"},
			{ value: "Cheque", text: "Cheque"}, 
			{ value: "DemandNote", text: "Demand Note"},
			{ value: "Invoice", text: "Sales Invoice"},
			{ value: "Invoice", text: "Purchase Invoice"},
			{ value: "Proforma", text: "Proforma Invoice"},
			{ value: "GoodsReceived", text: "Goods Received"},
			{ value: "GoodsIssued", text: "Goods Issued"},
			{ value: "DebitNote", text: "Debit Note"},
			{ value: "CreditNote", text: "Credit Note"},
			{ value: "OpenningBalance", text: "Openning Balance"}, 
			{ value: "ClosingBalance", text: "Closing Balance"},
			];   
		}


		// transaction_code	enum('PV','SR','PC','DV','JE','JP','CQ','DN','INV',
		// 	'PINV','GRN','GIN','DrN','CrN','OB','CB','DE')	


	}]); 