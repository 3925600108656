main.service('registerService', function (modalService, appFactory, notify, $modal, $http) {

	'use strict';

	var User = this;

	User.getUsers = function() {
		return $http({
			url : 'index.php/getUsers/',
		});
	};

	User.getInactive = function() {
		return $http({
			url : 'index.php/getInactive/',
		});
	};

});