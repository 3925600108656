main.service('taskService', function(modalService, appFactory, notify, $modal, $http) {
    'use strict';
    var Task = this;
    Task.getTasks = function() {
        return $http({
            url: 'index.php/getTasks/',
        });
    };
    Task.getObjectiveTasks = function(id) {
        return $http({
            method: 'GET',
            url: 'index.php/getObjectiveTasks/',
            params: {
                'id': id,
            }
        });
    }
     Task.getTaskStatuses = function(id) {
        return $http({
            method: 'GET',
            url: 'index.php/getTaskStatuses/',
            params: {
                'id': id,
            }
        });
    }
      Task.capturePDF = function(tasks,status) {
        return $http({
            url: 'index.php/capturePDF/',
            params: {
                'data': tasks,"status":status
            }
        });
    };

    "$cookies", function($cookies) {
        var Status = "";
        var Rank = "";

        return {
            setCookieData: function(Status,rank) {
                Status = status;
                Rank = rank;
                $cookies.put("Status", status, "Rank", rank);
            },
            getCookieData: function() {
                Status = $cookies.get("Status");
                Rank = $cookies.get("Rank");
                return Status;
                return Rank;
            },
            
        }
    }



});