main.service('MessagingService', function (modalService, appFactory, notify, $modal, $http) {
  'use strict';  
  var Message = this;

  Message.getList = function(modelname) { 
    return $http({
      method : 'GET', url : 'index.php/getList/', params : { 'modelname':modelname}
    });
  } 

  Message.ViewBatchDetails = function (templatename, windowClass, id){  
    var modalInstance = $modal.open({
      templateUrl: templatename,
      windowClass: windowClass,
      keyboard: false,
      backdrop: 'static', 
      resolve: {
        id: function () { return id; },
      },
      controller: function ($scope, $modalInstance, notify, id) {
        $http({
          'method' : 'GET', 'url' : 'index.php/GetBatchDetails/', params : { 'id' : id }
        }).success(function(data, status ){  
          $scope.Balances = data.list;  
        });  

        Message.cancel = function () {
          $modalInstance.dismiss('cancel');
        }; 
        
      }
    }); 
  };


  Message.OpenMessageSendingForm = function (formname, windowClass, Iterator){  
    var modalInstance = $modal.open({
      templateUrl: formname,
      windowClass: windowClass,
      keyboard: false,
      backdrop: 'static',
      resolve: {
        datalist: function () {
          return Iterator;
        }, 
      },
      controller: function ($scope, $modalInstance, datalist, notify) {   
        Message.SendGenericMessage = function (formdata) { 
          $http({
            'method':'POST','url':'index.php/SendGenericMessage', data:{'formdata':formdata },
          }).success(function(data,status){  
            datalist.push(data.insertedData);  
            $modalInstance.close();
            notify({
              message: data.message, 
              template:'tpls/notify/success.html',
              position:'center'
            });
          }).error(function(error,status){
            notify({
              message: error.message, 
              template:'tpls/notify/error.html',
              position:'center'
            });
          });  
        };

        Message.SendOpenMessage = function (formdata) { 
          $http({
            'method':'POST','url':'index.php/SendOpenMessage', data:{'formdata':formdata },
          }).success(function(data,status){  
            datalist.push(data.insertedData);  
            // $modalInstance.close();
            notify({
              message: data.message, 
              template:'tpls/notify/success.html',
              position:'center'
            });
          }).error(function(error,status){
            notify({
              message: error.message, 
              template:'tpls/notify/error.html',
              position:'center'
            });
          });  
        };

        Message.cancel = function () {
          $modalInstance.dismiss('cancel');
        }; 

      }
    }); 
  };



});