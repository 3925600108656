'use strict';  
angular.module('filters', []).filter('currency', function() {
	return function(number, currencyCode) {
		var currency = {
			TZS : "TZS ",
			USD : "$",
			MIXED : "~"
		}, thousand, decimal, format;
		if ($.inArray(currencyCode, ["TZS", "USD", "MIXED"]) >= 0) {
			thousand = ",";
			decimal = ".";
			format = "%s%v";
		} else {
			thousand = ",";
			decimal = ".";
			format = "%s%v";
		};  
		return accounting.formatMoney(number, { symbol: "TZS",  format: "%v %s" }); 
	};
});  

main.filter('strToInt', function() {
	return function(input) {
		if (input) {
			return parseInt(input,10);
		}else{
			return 'Not set';
		};
	};
});


main.filter('dateFormat2', function($filter)
{
	return function(input)
	{
		if(input == null){ return ""; } 
		var _date = $filter('date')(new Date(input), 'dd/MM/yyyy');

		return _date; 
	};
});


main.filter('sum', function() {
	return function(data,key) {
		if (typeof(data)==='undefined'||typeof(key)==='undefined'){
			return 0;
		}  
		var sum = 0;
		for (var i = data.length - 1; i >= 0; i--) {
			if (typeof(data[i][key])==='string'||typeof(data[i][key])==='number'){ 
				sum += parseFloat(data[i][key]);
			}
		} 
		return sum;
	};
});



main.filter('urlEncode', function() {
	return window.encodeURIComponent;
});

// to be deprecated!

// main.filter('filterStatus', function() {
// 	return function  (datas, key) { 

// 		if(datas == null){ 
// 			return ""; 
// 		}; 

// 		if (key == 0 || key == null) {
// 			return datas;
// 		};  

// 		for (var i = datas.length - 1; i >= 0; i--) {			
// 			if (datas.length > 0) {
// 				return datas.filter(function(data) {
// 					return data.status == key;
// 				});
// 			}
// 		}

// 	};
// });






main.filter('filterMonth',function() {
	return function  (datas, key) {
		var key = parseInt(key - 1);
		if (datas == null){ 
			return ""; 
		};

		// what if this month has no data, return all records(how?madofe, how?)

		if (key == 12 || key == 0 || key == null) {
			return datas;
		};  

		if (datas.length>0) {
			return datas.filter(function(data) {			
				return (new Date(data.sales_date).getMonth() == key ||
					new Date(data.purchase_date).getMonth() == key || 
					new Date(data.purch_date).getMonth() == key ||
					new Date(data.order_date).getMonth() == key ||
					new Date(data.followup_date).getMonth() == key ||
					new Date(data.expiry_date).getMonth() == key ||
					new Date(data.created_at).getMonth() == key ||
					new Date(data.settlement_date).getMonth() == key ||
					
					new Date(data.sign_date).getMonth() == key					
					);
			});
		}
	}
});

main.filter('stringFilter', function() {
	return function  (datas, key) {
		
		var field = key[0];
		var value = key[1];

		if (datas == null){
			return ""; 
		}; 

		if (value == null) {
			return datas;
		};  

		if (datas.length>0) {
			return datas.filter(function(data) { 
				return data[field] == value;
			});
		}
	}
});




main.filter('filterMonthMultField',function() {
	return function  (datas, key) {

		var field = key[0];
		var value = key[1];

		var value = parseInt(value - 1);
		if (datas == null){ 
			return ""; 
		}; 

		if (value == 12 || value == 0 || value == null) {
			return datas;
		};  

		if (datas.length>0) {
			return datas.filter(function(data) { 
				return new Date(data[field]).getMonth() == value;
			});
		}
	}
});



main.filter('abs', function() {
	return function(num) { return Math.abs(num); }
});



main.filter('ThisYear',function() {
	var ThisYear =(new Date()).getFullYear()

	return function  (datas, key) {
		if (datas.length>0) {
			return datas.filter(function(data) {			
				return (
					ThisYear == key				
					);
			});
		}
	}
});