angular.module('directives', []).directive('appVersion', ['version',
  function(version) {
   return function(scope, elm, attrs) {
    elm.text(version);
  };
}]);

main.directive('backButton', function(){
  return {
    restrict: 'A', 
    link: function(scope, element, attrs) {
      element.bind('click', goBack); 
      function goBack() {
        history.back();
        scope.$apply();
      }
    }
  };
});

main.directive("list", function(restService, $rootScope) {
  return {
    restrict: 'A',
    link: function(scope, el, attrs, ctrl){
      var id = scope.$eval(attrs.list).id;
      var modelname = scope.$eval(attrs.list).modelname; 
      restService.getItem(id, modelname).success(function (data) {
        $rootScope.Item  = data.item;
      });
    },
    template: "<span>{{ Item.nice_name }}</span>"
  }
});

main.directive('includeReplace', function () {
  return {
    require: 'ngInclude',
    restrict: 'A', /* optional */
    link: function (scope, el, attrs) {
      el.replaceWith(el.children());
    }
  };
});

main.directive('datepickerLocaldate', ['$parse', function ($parse) {
  var directive = {
    restrict: 'A',
    require: ['ngModel'],
    link: link
  };
  return directive;

  function link(scope, element, attr, ctrls) {
    var ngModelController = ctrls[0];
    
    ngModelController.$parsers.push(function (viewValue) {

      viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
      
      return viewValue.toISOString().substring(0, 10);
    });
    
    ngModelController.$formatters.push(function (modelValue) {
      if (!modelValue) {
        return undefined;
      }
      var dt = new Date(modelValue); 
      dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
      return dt;
    });
  }
}]);

main.directive('fixedTableHeaders', ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $timeout(function() {
        container = element.parentsUntil(attrs.fixedTableHeaders);
        element.stickyTableHeaders({ scrollableArea: container, "fixedOffset": 70 });
      }, 0);
    }
  }
}]); 