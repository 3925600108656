main.service('budgetsService', function (modalService, appFactory, notify, $modal, $http) {

  'use strict';

  var Budget = this;

  Budget.getBudgets = function() {
    return $http({
      url : 'index.php/getBudgets/',
    });
  };

Budget.getBudgetsPDF = function(budgets,deptId,yearId) {
    return $http({
      url : 'index.php/FirePDF/',
      params:{
        'data':budgets,"deptId":deptId,"yearId":yearId
    }
    });
  };

Budget.getBudgetsEXCEL = function(budgets,deptId,yearId) {
    return $http({
      url : 'index.php/FetchExcel/xls/',
      params:{
        'data':budgets,"deptId":deptId,"yearId":yearId
      }
    });
  };


});