'use strict';

main.controller('UserSettingsController', ['$rootScope', '$scope','UserSettingsService', 'RestApiService', '$modal', '$log', 'notify', '$filter', 
	
	function($rootScope, $scope, UserSettingsService, RestApiService, $modal, $log, notify, $filter) {

		$scope.Title = "Employees Settings";   

		$scope.module_root = "Home";  
		$scope.module_name = "Employees Settings";    
		$scope.submod_name = "Employees Settings";   

		$scope.crumbr_root = "Settings";  
		$scope.crumbr_url  = "user_settings";

		$scope.module_url  = "user_settings";
		$scope.submod_url  = "user_settings";

		$scope.printableSection = "printableSection";


		$scope.EmployeesList_url_status = 'disabled';  
		$scope.crumb = $scope.url_label = ` Staff Settings `;  
		
		$rootScope.getGenders = function () {
			$rootScope.GenderList = [
			{ value: "Male",  text: "Male" },
			{ value: "Female",  text: "Female" },
			];
		};

		

		$rootScope.getProfiles = function () {
			$rootScope.ProfileList = [
			{ value: "Admin", text: "Admin" },
			{ value: "User",  text: "User" },
			{ value: "View",  text: "View" },
			];
		};



		$rootScope.GetDesignations = function () { 
			RestApiService.getList('Designation').success(function(data, status) {   
				$rootScope.Designation = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}


		$rootScope.GetDepartments  = function () { 
			RestApiService.getList('Department').success(function(data, status) {   
				$rootScope.Department = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

		
		$scope.GetEmployees = function () { 
			UserSettingsService.GetEmployeeList().success(function(data, status) {   
				$scope.Employee = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

		$scope.GetUsers = function () { 
			RestApiService.getList('User').success(function(data, status) {   
				$scope.Employee = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		} 


		$scope.GetOwnUserProfile = function () {  
			UserSettingsService.GetOwnUserProfile().success(function(data, status) {   
				$scope.Employee = data.item;
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}  
		

		$scope.ResetPassword = function(id) {
			UserSettingsService.ResetPassword(id);
		}

		$scope.OpenChangePassword = function(tpltname, windowClass) {
			UserSettingsService.OpenChangePassword(tpltname, windowClass);
		}

		$scope.ChangePassword = function(formdata) {
			UserSettingsService.ChangePassword(formdata);
		}
		$scope.closePassModel = function () {
			UserSettingsService.closePassModel();
		}
		



		$scope.OpenDesignationDetails = function (tpltname, windowClass, id) {
			UserSettingsService.OpenDesignationDetails(tpltname, windowClass, id);
		}; 
		$scope.cancelDesignation = function () {
			UserSettingsService.closeDesignation();
		}



		$scope.OpenDepartmentDetails = function (tpltname, windowClass, id) {
			UserSettingsService.OpenDepartmentDetails(tpltname, windowClass, id);
		}; 
		$scope.cancelDepartment = function () {
			UserSettingsService.closeDepartment();
		}



		$scope.OpenEmployeeProfile = function (tpltname, windowClass, id) {
			UserSettingsService.OpenEmployeeProfile(tpltname, windowClass, id);
		}; 
		$scope.cancelProfile = function () {
			UserSettingsService.closeProfile();
		}

		
		
		$scope.openUsersForm = function (formname, windowClass, Iterator) {
			UserSettingsService.openUsersForm(formname, windowClass, Iterator);
		};
		$scope.saveUsers = function (formdata) {
			UserSettingsService.saveUsers(formdata);
		};
		$scope.cancelUserForm = function () {
			UserSettingsService.closeForm();
		}






		$scope.openListForm = function (formname, windowClass, Iterator) {
			RestApiService.openListForm(formname, windowClass, Iterator);
		};
		$scope.saveRestForm = function (formdata, modelname) {
			RestApiService.postList(formdata, modelname);
		};
		$scope.postRestItem = function (formdata, modelname) {
			RestApiService.postItem(formdata, modelname);
		};
		$scope.cancelListForm = function () {
			RestApiService.closeListForm();
		}





	}]); 