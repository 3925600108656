'use strict';
main.controller('ReviewsController', ['$rootScope','$interval','ReviewsService', '$scope','$http', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams','$filter', 'restService', '$state', '$stateParams',
  
  function($rootScope,$interval,ReviewsService, $scope,$http, appFactory, $modal, $log, notify, modalService, ngTableParams,$filter,restService, $state, $stateParams) {
    
    ReviewsService.getReviews().success(function(data, status) {
      $scope.users = data.users; 
      $scope.role=data.role;
    });

    //get a single item.
    $rootScope.objectiveId = objectiveId = $stateParams.objectiveId;

    restService.getItem(objectiveId, 'Objective').success(function(data){
      $rootScope.objective = data.item;

    }) 

    $scope.reviews = restService.intiateNgTable(ReviewsService.getObjectiveReviews(objectiveId), $scope);     
    
        //disply modalform.
        $scope.toggle = function(modalstate, id) {
          $scope.modalstate = modalstate;

          switch (modalstate) {
            case 'add':
            $scope.form_title = "Add Review Summary";
            break;
            
          }
          $('#myModal').modal('show');
        }
     //save new record & update existing record
     $rootScope.save = function(modalstate, id) {
      var url = "reviews";
      formdata = $scope.review;  
      objectiveId = $rootScope.objectiveId;
      angular.extend(formdata,{"objId":objectiveId})
      console.log(formdata)
      $http({
        method: 'POST',
        url: url,
        data: $.param(formdata),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }).success(function(response) {
       location.reload();
     }).error(function(response) {
      console.log(response);
      alert('An error has occured. Please check the log for details');
    });
   }


   $scope.deleteItem = function(id, modelname) {
    restService.deleteItem(id, modelname)
  }
  $scope.updateRow = function(id, modalname, data) {
    restService.updateRow(id, modalname, data);
  }
  


  $(".todolist").focus(function() 
    {    if(document.getElementById('comment').value === ''){
      document.getElementById('comment').value +=' ';
    }
  });
  $(".todolist").keyup(function(event){
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      document.getElementById('comment').value +='• ';
    }
    var txtval = document.getElementById('comment').value;
    if(txtval.substr(txtval.length - 1) == '\n'){
      document.getElementById('comment').value = txtval.substring(0,txtval.length - 1);
    }
  });


  $scope.today = new Date();
  var tick = function() {
   $scope.clock = Date.now();
 }
 tick();
 $interval(tick, 1000);




}]); 

