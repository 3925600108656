main.service('ReviewsService', function(modalService, appFactory, notify, $modal, $http) {
    'use strict';
    var Review = this;
    Review.getReviews = function() {
        return $http({
            url: 'index.php/getReviews/',
        });
    };
    Review.getObjectiveReviews = function(id) {
        return $http({
            method: 'GET',
            url: 'index.php/getObjectiveReviews/',
            params: {
                'id': id,
            }
        });
    }
     
    // "$cookies", function($cookies) {
    //     var Status = "";
    //     var Rank = "";

    //     return {
    //         setCookieData: function(Status,rank) {
    //             Status = status;
    //             Rank = rank;
    //             $cookies.put("Status", status, "Rank", rank);
    //         },
    //         getCookieData: function() {
    //             Status = $cookies.get("Status");
    //             Rank = $cookies.get("Rank");
    //             return Status;
    //             return Rank;
    //         },
            
    //     }
    // }



});