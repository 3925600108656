'use strict';
main.controller('taskController', ['$rootScope', '$interval', 'registerService', '$window', 'taskService', '$scope', '$http', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state', '$stateParams',
    function($rootScope, $interval, registerService, $window, taskService, $scope, $http, appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state, $stateParams) {

        taskService.getTasks().success(function(data, status) {
            $scope.users = data.users;
            $scope.role = data.role;

        });

        $scope.searchTask = function(searchedTask) {
            $scope.searchedTerm = null;
            $scope.searchedTerm = searchedTask;
            $scope.tasks.reload();
        } 

        //get a single item.
        
        $rootScope.objectiveId = objectiveId = $stateParams.objectiveId;
        restService.getItem(objectiveId, 'Objective').success(function(data) {
            $rootScope.objective = data.item;
        })

        $scope.tasks = restService.intiateNgTable(taskService.getObjectiveTasks(objectiveId), $scope);

        $scope.downloadPDF = function(tasks,status) {
            console.log(status)
            taskService.capturePDF(tasks,status).success(function(data, status) {
                var url = 'index.php/capturePDF/?data=' + tasks+"&status="+status;
                $window.open(url);
            });
        };  


        $scope.taskStatuses = [
        {
            'text': 'New',
            'value': 'New'
        },
        {
            'text': 'Pending',
            'value': 'Pending'
        }, 

        {
            'text': 'In-progress',
            'value': 'In-progress'
        }, 
        {
            'text': 'Completed',
            'value': 'Completed'
        },
        
        {
            'text': 'Due',
            'value': 'Due'
        }];

        $scope.taskPercentages = [
        {
            'number': '0',
            'value': '0'
        },

        {
            'number': '5',
            'value': '5'
        },

        {
            'number': '10',
            'value': '10'
        },
        {
            'number': '25',
            'value': '25'
        }, 

        {
            'number': '50',
            'value': '50'
        }, 
        {
            'number': '75',
            'value': '75'
        },
        
        {
            'number': '100',
            'value': '100'
        }];


        $scope.options = {
            thickness: 80
        };



        taskService.getObjectiveTasks(objectiveId).success(function(data) {
            tasks = data.data;
            var totalPending = 0;
            var totalCompleted = 0;
            var totalProgress = 0;
            var totalNew = 0;
            var total = 0;
            for (var i = 0; i < tasks.length; i++) {
                if (tasks[i].status == "In-progress") {
                    totalProgress++;
                }
                if (tasks[i].status == "Completed") {
                    totalCompleted++;
                }
                if (tasks[i].status == "New") {
                    totalNew++;
                }
                if (tasks[i].status == "Pending") {
                    totalPending++;
                }
                total++;
            }
            percentPending = (total) ? parseFloat(totalPending / total * 100).toFixed(1) : 0;
            percentCompleted = (total) ? parseFloat(totalCompleted / total * 100).toFixed(1) : 0;
            percentProgress = (total) ? parseFloat(totalProgress / total * 100).toFixed(1) : 0;
            percentNew = (total) ? parseFloat(totalNew / total * 100).toFixed(1) : 0;
            // console.log(percentCompleted);
            if (total > 0) {
                $scope.Statuses = [{
                    label: "%Pending ",
                    value: percentPending,
                    suffix: "%",
                    color: "red"
                }, 
                {
                    label: "%Completed ",
                    value: percentCompleted,
                    suffix: "%",
                    color: "#00ff00"
                },
                {
                    label: "%New ",
                    value: percentNew,
                    suffix: "%",
                    color: "orange"
                }, 
                {
                    label: "%In progress",
                    value: percentProgress,
                    suffix: "%",
                    color: "rgb(0, 0, 255)"
                }]
            } else {
                $scope.Statuses = [];
            }
        })

        $scope.taskRanks = [{
            'text': '1',
            'value': '1'
        }, {
            'text': '2',
            'value': '2'
        }, {
            'text': '3',
            'value': '3'
        }, {
            'text': '4',
            'value': '4'
        }, {
            'text': '5',
            'value': '4'
        }];
        //   $scope.task = {
        //   task_timeline: new Year(1950)
        // };
        // $scope.opened = {};
        // $scope.open = function($event, elementOpened) {
        //     $event.preventDefault();
        //     $event.stopPropagation();
        //     $scope.opened[elementOpened] = !$scope.opened[elementOpened];
        // };
        //disply modalform.
        $scope.toggle = function(modalstate, id) {
            $scope.modalstate = modalstate;
            switch (modalstate) {
                case 'add':
                $scope.form_title = "Add New Task";
                break;
            }
            $('#myModal').modal('show');
        }
        //save new record & update existing record
        $rootScope.save = function(modalstate, id ,action) {
            var url = "tasks";
            formdata = $scope.task;
            objectiveId = $rootScope.objectiveId;
            angular.extend(formdata, {
                "objId": objectiveId
            })
            console.log(formdata)
            $http({
                method: 'POST',
                url: url,
                data: $.param(formdata),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).success(function(response) {
                if (action == 'close') {
                    location.reload();
                }else{
                    $scope.task.tsk_desc = "";
                    $scope.task.name = "";
                    $scope.task.task_timeline = "";
                    $scope.task.status = "";
                    $scope.task.rank = "";
                    console.log($scope.task)
                    $scope.submissionSuccess = "true";
                }
            }).error(function(response) {
                console.log(response);
                alert('An error has occured. Please check the log for details');
            });
        }
        $scope.edit = function(id) {
            if (modalstate === 'edit') {
                url += "/" + id;
            }
            dataFactory.httpRequest('tasks/' + id + '/edit').then(function(data) {
                console.log(data);
                location.reload();
                $scope.form_title = data;
            });
        }
        $scope.saveEdit = function() {
            location.reload();
            dataFactory.httpRequest('tasks/' + $scope.form_title.id, 'PUT', {}, $scope.form_title).then(function(data) {
                $(".modal").modal("hide");
                $scope.data = apiModifyTable($scope.data, data.id, data);
            });
        }
        $scope.deleteItem = function(id, modelname) {
            restService.deleteItem(id, modelname)
        }
        $scope.updateRow = function(id, modalname, data) {
            restService.updateRow(id, modalname, data,notify);

            location.reload()
            notify({
                message: data.message, 
                template:'tpls/notify/success.html',
                position:'center'
            });
        }
        $(".todolist").focus(function() {
            if (document.getElementById('tsk_desc').value === '') {
                document.getElementById('tsk_desc').value += ' ';
            }
        });
        $(".todolist").keyup(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                document.getElementById('tsk_desc').value += '• ';
            }
            var txtval = document.getElementById('tsk_desc').value;
            if (txtval.substr(txtval.length - 1) == '\n') {
                document.getElementById('tsk_desc').value = txtval.substring(0, txtval.length - 1);
            }
        });
        $scope.today = new Date();
        var tick = function() {
            $scope.clock = Date.now();
        }
        tick();
        $interval(tick, 1000);

        $scope.toggle = function(modalstate, deptId,modalId) {
            $scope.modalstate = modalstate;
            switch (modalstate) {
                case 'add':
                        // $scope.form_title = "Select Report To Export";
                        break;
                    }
                    $(modalId).modal('show');
                }
                $(function() {

                  $('input[name="datefilter"]').daterangepicker({
                      autoUpdateInput: false,
                      locale: {
                          cancelLabel: 'Clear'
                      }
                  });

                  $('input[name="datefilter"]').on('apply.daterangepicker', function(ev, picker) {
                      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
                  });

                  $('input[name="datefilter"]').on('cancel.daterangepicker', function(ev, picker) {
                      $(this).val('');
                  });

              });

            }
            ]);