main.controller('RestController', ['$http', '$rootScope', '$scope', 'RestApiService', '$modal', '$log', 'notify', '$stateParams','Paginator', '$filter', 
	
	function($http, $rootScope, $scope, RestApiService, $modal, $log, notify, Paginator,  $filter) {
		
		$http({
			'method':'GET','url':'index.php/rbac/',
		}).success(function(data, status, headers, config) { 
			$rootScope.rbac = data.profile;
		});  

		$rootScope.Currencies = function () { 
			RestApiService.getList('Currency').success(function(data, status) {   
				$rootScope.Currency = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

		$rootScope.getGenders = function () {
			$rootScope.GenderList = [
			{ value: "Male",  text: "Male" },
			{ value: "Female",  text: "Female" },
			];
		};

		$rootScope.GetBooList = function () {
			$rootScope.BooList = [
			{ value: "No",  text: "No" },
			{ value: "Yes",  text: "Yes" },
			];
		};



		$rootScope.getProfiles = function () {
			$rootScope.ProfileList = [
			{ value: "Admin", text: "Admin" },
			{ value: "User",  text: "User" },
			{ value: "View",  text: "View" },
			];
		};


		$rootScope.GetCompanyTypes = function () {
			$rootScope.CompanyTypes = [
			{ value: "propriatorpropriator", text: "Propriator" },
			{ value: "joint venture",  text: "Joint venture" },
			{ value: "partnership",  text: "Partnership" }, 
			{ value: "limited",  text: "Limited" },
			{ value: "unlimited",  text: "Unlimited" },
			{ value: "corporate",  text: "Corporate" },
			{ value: "incorporate",  text: "Incorporate" },
			];
		}; 

		$rootScope.GetCompanyTrades = function () {
			$rootScope.CompanyTrades = [
			{ value: "products", text: "Products" },
			{ value: "services",  text: "Services" },
			{ value: "products & services",  text: "Products & services" }, 
			{ value: "manufactures",  text: "Manufactures" }, 
			];
		};



		$rootScope.openListForm = function (formname, windowClass, Iterator) {
			RestApiService.openListForm(formname, windowClass, Iterator);
		};
		$rootScope.saveRestForm = function (formdata, modelname) {
			RestApiService.postList(formdata, modelname);
		};
		$rootScope.postRestItem = function (formdata, modelname) {
			RestApiService.postItem(formdata, modelname);
		};
		$rootScope.cancelListForm = function () {
			RestApiService.closeListForm();
		}





		$rootScope.OpenMultiModelForm = function (formname, windowClass, Iterator) {
			RestApiService.OpenMultiModelForm(formname, windowClass, Iterator);
		};
		$rootScope.PostMultiModelForm = function (formdata, rowsdata, majormodel, childmodel) {
			RestApiService.PostMultiModelForm(formdata, rowsdata, majormodel, childmodel);
		};
		$rootScope.CancelMultiModelForm = function () {
			RestApiService.CancelMultiModelForm();
		}








		
		$rootScope.rows = [{}];  

		$rootScope.addRow = function(){ 
			if($rootScope.rows.length < 10){
				$rootScope.rows.push({}); 
			}
		};

		$rootScope.removeRow = function($index){
			if($rootScope.rows.length != 1){ 
				$rootScope.rows.splice($index , 1);
			}
		};

		$rootScope.postList = function (dict, modelname, valid) {
			RestApiService.postList(dict, modelname, valid);
		}

		$rootScope.deleteItem = function ($index, id, modelname, Iterator) {
			RestApiService.deleteItem($index, id, modelname, Iterator);
		}

		$rootScope.updateRow = function ($index, id, data, modelname, Iterator) {
			RestApiService.updateRow($index, id, modelname, data, Iterator);
		}
		
		$rootScope.updateColumn = function (data, modelname) {  
			RestApiService.updateColumn(data, modelname);
		}
		
		var ids = [];
		var indexes = [];
		$rootScope.deleteIndex = true;
		$rootScope.pickList = function ($index, rows, modelname) {
			if (rows.checkId) {
				if (rows) { 
					$rootScope.deleteIndex = false;
				};
				indexes.push($index);
				ids.push(rows.id) ;
			}else{ 
				var index = ids.indexOf(rows.id);
				ids.splice(index, 1);

				var index_item = indexes.indexOf($index);
				indexes.splice(index_item, 1);
				if (indexes.length == 0) {
					$rootScope.deleteIndex = true;
				};  
			};	
		}  

		$rootScope.deleteList = function (modelname, Iterator) { 
			RestApiService.deleteList(indexes, ids, modelname, Iterator);
			ids = [];
			indexes = [];
		}

		$rootScope.getItemById = function (id, modelname) { 
			RestApiService.getItem(id, modelname).success(function (data) {
				$rootScope.Item  = data.item;   
			});
		}
		

		$rootScope.checkDataExistence = function (column, modelname, formdata) {
			RestApiService.checkDataExistence(column, modelname, formdata).success(function (data) {
				$rootScope.returnedMessage = data.message;
			});
		};

		$rootScope.TransactionValidity = function(row) { 
			// row - list of repeated entries for a specific transaction.
			var total = {}; 
			var total_credit = 0;
			var total_debit  = 0;
			for (var i = 0; i < row.length; i++) {
				total_credit += (parseFloat(( row[i].amount_cr )));  
				total_debit  += (parseFloat(( row[i].amount_dr )));  
			}
			total.total_credit = total_credit;  
			total.total_debit  = total_debit;   
			$rootScope.Total   = total; 
			// html button used as
			// <button ng-hide="Total.total_credit != Total.total_debit">
		};



		$rootScope.pageNumber = function (page) {
			$rootScope.pageIndex = parseInt(page + 1); 
		}

		$rootScope.truncator = function (selectedCount) { 
			$rootScope.rowsPerPage = selectedCount;   
		} 

		$rootScope.counters = function (listLength) {
			if (listLength) {
				if (listLength >= 5 && listLength <= 10) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					];
					if (listLength < 10) {
						$rootScope.dataList = [
						{ value: listLength,  text: 'All' },
						];
					}
				}


				if (listLength > 10 && listLength <= 15) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "15",  text: "15" },
					];
					if (listLength < 15) {
						$rootScope.dataList = [
						{ value: "10",  text: "10" },
						{ value: listLength,  text: 'All' },
						];
					}
				}


				if (listLength > 15 && listLength <= 20) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "15",  text: "15" },
					{ value: "20",  text: "20" },
					];

					if (listLength < 20) {
						$rootScope.dataList = [
						{ value: "10",  text: "10" },
						{ value: "15",  text: "15" },
						{ value: listLength,  text: 'All' },
						];
					}
				}


				if (listLength > 20 && listLength <= 25) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "15",  text: "15" },
					{ value: "20",  text: "20" },
					{ value: "25",  text: "25" },
					];
					if (listLength < 25) {
						$rootScope.dataList = [
						{ value: "10",  text: "10" },
						{ value: "15",  text: "15" },
						{ value: "20",  text: "20" },
						{ value: listLength,  text: 'All' },
						];
					}
				}


				if (listLength > 25 && listLength <= 50) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "25",  text: "25" },
					{ value: "50",  text: "50" },
					];
					if (listLength < 50) {
						$rootScope.dataList = [
						{ value: "10",  text: "10" },
						{ value: "15",  text: "15" },
						{ value: "20",  text: "20" },
						{ value: "25",  text: "25" },
						{ value: listLength,  text: 'All' },
						];
					}
				}

				if (listLength > 50 && listLength <= 100) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "25",  text: "25" },
					{ value: "50",  text: "50" },
					{ value: "100",  text: "100" },
					];
					if (listLength < 100) {
						$rootScope.dataList = [
						{ value: "10",  text: "10" },
						{ value: "15",  text: "15" },
						{ value: "20",  text: "20" },
						{ value: "25",  text: "25" },
						{ value: "50",  text: "50" },
						{ value: listLength,  text: 'All' },
						];
					}
				}

				if (listLength > 100) {
					$rootScope.dataList = [
					{ value: "10",  text: "10" },
					{ value: "15",  text: "15" },
					{ value: "20",  text: "20" },
					{ value: "25",  text: "25" },
					{ value: "50",  text: "50" },
					{ value: "100",  text: "100" },
					{ value: listLength,  text: 'All' },
					];
				}
			}
		};



	}]); 