main.controller('MessagingController', ['$rootScope','$stateParams', '$scope', 'appFactory', 'MessagingService', 'SuppliersService','restService', '$modal', '$log', 'notify', 'modalService', 'ngTableParams','$filter', 
	
	function($rootScope, $stateParams, $scope, appFactory, MessagingService, SuppliersService, restService, $modal, $log, notify, modalService, ngTableParams,$filter) {
		'use strict';
		
		$scope.Title = "Messaging";   
		
		$scope.module_root = `<i class="glyphicon glyphicon-home"></i> Home`;  
		$scope.module_name = `<i class="glyphicon glyphicon-envelope"></i> Messaging`;     
		$scope.module_url  = "messaging"; 

		$scope.MessagingDashboard =  function() {
			$scope.messaging_dash_url_status  = 'disabled';
			$scope.printableSection  = "printMessagingDash"; 
			$scope.crumb = $scope.url_label = ` Dashboard `; 
		} 



		$scope.MessagingTemplates =  function() { 
			$scope.templates_url_status  = 'disabled';
			$scope.printableSection  = "printMessagingTemplates"; 
			$scope.crumb = $scope.url_label = ` Message templates `; 

			MessagingService.getList('Template').success(function(data, status) {   
				$scope.Template = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		} 


		$scope.MessagingRecipients =  function() {
			$scope.recipients_url_status  = 'disabled';
			$scope.printableSection  = "printMessagingRecipients"; 
			$scope.crumb = $scope.url_label = ` Message recipients `;

			MessagingService.getList('Recipient').success(function(data, status) {   
				$scope.Recipient = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			});  
		} 

		$scope.MessageTypes =  function() {
			$scope.MessageTypes =[
			{ value: "sms",   text: "SMS" },
			{ value: "email", text: "Email" },
			{ value: "both",  text: "SMS & Email" }, 
			]; 
		}

		$scope.MessagingBatches =  function() {
			$scope.batches_url_status  = 'disabled';
			$scope.printableSection  = "printMessagingBatches"; 
			$scope.crumb = $scope.url_label = ` Message batches `; 

			MessagingService.getList('Batch').success(function(data, status) {   
				$scope.Batch = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		} 


		$scope.ProgrammedMessaging =  function() {
			$scope.programmed_messaging_url_status  = 'disabled';
			$scope.printableSection  = "printProgrammedMessaging"; 
			$scope.crumb = $scope.url_label = ` Bulk messaging `; 

			MessagingService.getList('Batch').success(function(data, status) {   
				$scope.Batch = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			});  
		} 

		$scope.OpenMessaging =  function() {
			$scope.open_messaging_url_status  = 'disabled';
			$scope.printableSection  = "printOpenMessaging"; 
			$scope.crumb = $scope.url_label = ` Open Messaging `; 

			MessagingService.getList('Batch').success(function(data, status) {   
				$scope.Batch = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			});  
		} 
		
		$scope.OpenMessageSendingForm = function (templatename, windowClass, Iterator) { 
			MessagingService.OpenMessageSendingForm(templatename, windowClass, Iterator);
		}
		$scope.cancel = function () {
			MessagingService.cancel();
		};
		$scope.SendGenericMessage = function (formdata) {  
			MessagingService.SendGenericMessage(formdata);
		} 

		$scope.SendOpenMessage = function (formdata) {  
			MessagingService.SendOpenMessage(formdata);
		} 




		$scope.MessagingReports =  function() {
			$scope.messaging_reports_url_status  = 'disabled';
			$scope.printableSection  = "printMessageReports"; 
			$scope.crumb = $scope.url_label = ` Message reports `; 
		} 





		$scope.ViewBatchDetails = function (templatename, windowClass, id) { 
			MessagingService.ViewBatchDetails(templatename, windowClass, id);
		}

		$scope.cancel = function () {
			MessagingService.cancel();
		};








	}]); 