main.service('yearsService', function (modalService, appFactory, notify, $modal, $http) {

  'use strict';

  var Year = this;

  Year.getYears = function() {
    return $http({
      url : 'index.php/getYears/',
    });
  };

});