
'use strict';
main.controller('objectivesController', ['$rootScope','$interval','registerService','rememberService', 'ObjectivesService', 'growl', 'taskService', '$scope', '$window', '$http', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state', '$stateParams',
    function($rootScope,$interval,registerService,rememberService, ObjectivesService, growl, taskService, $scope, $window, $http, appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state, $stateParams) {
        $rootScope.reloadPage = function() {   
            $state.transitionTo($state.current, $stateParams, { reload: true, inherit: true, notify: true }); 
        };


        ObjectivesService.getObjectives().success(function(data) {
            $rootScope.departments = data.departments;
            $rootScope.statuses = data.statuses;
            $rootScope.years = data.years;
            $rootScope.users = data.users;
            $rootScope.role = data.role;
            $rootScope.userDept = data.userDept;
            $rootScope.Year = data.Year;
            $scope.objectives = restService.intiateNgTable(ObjectivesService.getObjectives(), $scope);
            $scope.searchedTerm = null;
            $scope.searchedTerm = {yearc: $rootScope.Year, dpt_name: $rootScope.userDept};
            $scope.objectives.reload();
        });

        $scope.searchObjective = function(searchedObjective) {
            console.log(searchedObjective)
            $scope.searchedTerm = null;
            if (searchedObjective.dpt_name == 'All Departments') {
                searchedObjective = undefined;

            }

            // console.log(searchedObjective);
            $scope.searchedTerm = searchedObjective;
            $scope.objectives.reload();
        }

        $scope.downloadPDF = function(objectives ,deptId,yearId) {
            console.log(deptId)
            ObjectivesService.getObjectivesPDF(objectives,deptId,yearId).success(function(data, status) {
                var url = 'index.php/getPDF/?data=' + objectives+"&deptId="+deptId+"&yearId="+yearId;
                $window.open(url);
            });
        };
        $scope.getExcel = function(objectives ,deptId,yearId) {
            ObjectivesService.getObjectivesEXCEL(objectives).success(function(data, status) {
                var url = 'index.php/getExcel/xls/?data=' + objectives +"&deptId="+deptId+"&yearId="+yearId;
                $window.open(url);
            });
        };
        $scope.objective = {
            last_review: new Date()
        };
        $scope.opened = {};
        $scope.open = function($event, elementOpened) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened[elementOpened] = !$scope.opened[elementOpened];
        };
        $scope.saveObjectiveDate = function(objective) {
            item = {};
            item.last_review = $filter('date')(objective.last_review, "yyyy-MM-dd");
            restService.updateRow(objective.id, 'Objective', item);
        }

        $scope.saveObjectiveDate1 = function(objective) {
            item = {};
            item.strt_date = $filter('date')(objective.strt_date, "yyyy-MM-dd");
            restService.updateRow(objective.id, 'Objective', item);
        }
        $scope.saveObjectiveDate2 = function(objective) {
            item = {};
            item.end_date = $filter('date')(objective.end_date, "yyyy-MM-dd");
            restService.updateRow(objective.id, 'Objective', item);
        }
        //disply modalform.
        $scope.toggle = function(modalstate, $scope, objId ,modalId) {
            $scope.modalstate = modalstate;
            $scope.yearc = null;
            $scope.init = function(value) {
                $scope.yearc = value;
                console.log($scope.yearc);
            }
            switch (modalstate) {
                case 'add':
                $scope.form_title = "Add New Objective";
                break;
            }
            $(modalId).modal('show');
        }
        //save new record & update existing record
        $scope.save = function(modalstate,objId,action,modalId) {
            var url = "objectives";
            $http({
                method: 'POST',
                url: url,
                data: $.param($scope.objective),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).success(function(response) {
                console.log(response)
                notify({
                    message:'Objective successfully Added',
                    template:'tpls/notify/success.html',
                    position:'center',
                })
                if (action == 'close') {
                    // location.reload();
                } else {
                    $scope.objective.arch_driver = "";
                    $scope.objective.end_date = "";
                    $scope.objective.obj_desc = "";
                    $scope.objective.obj_status = "";
                    $scope.objective.perfo_indic = "";
                    $scope.objective.strt_date = "";
                    $scope.objective.verif_means = "";
                    $scope.objective.verif_means = "";
                    console.log($scope.objective)
                    $scope.submissionSuccess = "true";
                }
                notify({
                    message:'Objective successfully Added',
                    template:'tpls/notify/success.html',
                    position:'center',
                })
            }).error(function(response) {
                console.log(response);
                alert('An error has occured. Please check the log for details');
            });
        }

        $scope.moveNext = function(objId,year) {
            console.log(objId)
            var url = "moveNext";
            $http({
                method: 'GET',
                url: url,
                params: {objId:objId,year:year},

            }).success(function(message,response) {
                notify({
                    message:'Objective successfully moved to the next year',
                    template:'tpls/notify/success.html',
                    position:'center',
                })
            }).error(function(response) {
                alert('An error has occured. Please check the log for details');
            });
        }



        $scope.showSuccess = function(success, modalstate, objId, action) {
            growl.success('Objective has been successfully added.');
        }
        $scope.edit = function(objId) {
            if (modalstate === 'edit') {
                url += "/" + objId;
            }
            dataFactory.httpRequest('objectives/' + objId + '/edit').then(function(data) {
                console.log(data);
                $rootScope,reloadPage();
                $scope.form_title = data;
            });

        }
        $scope.saveEdit = function() {
            dataFactory.httpRequest('objectives/' + $scope.form_title.objId, 'PUT', {}, $scope.form_title).then(function(data) {
                $(".modal").modal("hide");
                $scope.data = apiModifyTable($scope.data, data.objId, data);
            });

        }

        $scope.deleteItem = function(id, modelname) {
            restService.deleteItem(id, modelname)
        }
        $scope.updateRow = function(id,modalname,data) {
            console.log(id);
            restService.updateRow(id,modalname,data);
        }





        $scope.today = new Date();
        var tick = function() {
           $scope.clock = Date.now();
       }
       tick();
       $interval(tick, 1000);

       $scope.objective.endDate = '20140313T00:00:00';

       $scope.toggle = function(modalstate, deptId,modalId) {
        $scope.modalstate = modalstate;
        switch (modalstate) {
            case 'add':
            $scope.form_title = "Select Report To Export";
            break;
        }
        $(modalId).modal('show');
    }

}
]);
