main.service('homeService', function(modalService, appFactory, notify, $modal, $http) {
	'use strict';
	var Objective = this;
	Objective.fetchObjectives = function() {
		return $http({
			url: 'index.php/fetchObjectives/',
		});
	};

	Objective.graphObjectives = function() {
		return $http({
			url: 'index.php/graphObjectives/',
		});
	};

});