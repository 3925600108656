main.service('currencyServices', function (modalService, appFactory, notify, $modal, $http) {

  'use strict';

  var Currency = this;

  Currency.getCurrencies = function() {
    return $http({
      url : 'index.php/getCurrencies/',
    });
  };



});
