main.service('ReportsService', function (modalService, appFactory, notify, $modal, $http) {

	'use strict';

	var Report = this;

	Report.getReports = function() {
		return $http({
			url : 'index.php/getReports/',
		});
	};



});