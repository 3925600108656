main.controller('EmployeesController', ['$rootScope', '$scope', 'appFactory','$location', 'EmployeesService', '$modal', '$log', 'notify', 'modalService', 'ngTableParams','$filter',

	function($rootScope, $scope, appFactory, $location, EmployeesService, $modal, $log, notify, modalService, ngTableParams,$filter) {

		'use strict';

		$scope.Title = "Employees settings";

		$scope.module_root = "Home";
		$scope.module_name = "Settings";
		$scope.submod_name = "Dashboard";

		$scope.module_url  = "system_settings/employees";
		$scope.submod_url  = "system_settings/employees";

		$scope.EmployeesList = function () {

			$scope.module_name = ` Settings `;  

			$scope.EmployeesList_url_status = 'disabled';  
			$scope.crumb = $scope.url_label = ` Employees `; 

			$scope.printableSection = "printEmployeesList";
			EmployeesService.getList().success(function(data, status) {
				$scope.Employee = data.list;
			}).error(function(data, status) {
				alert('something went wrong');
			});
		};


		$scope.cancel = function () {
			EmployeesService.cancel();
		};

		$scope.openEmployeesForm = function (formname, windowClass, modelname, Iterator) {
			EmployeesService.openForm(formname, windowClass, modelname, Iterator);
		};

		$scope.postList = function (dict, modelname, valid) {
			EmployeesService.postList(dict, modelname, valid);
		};




		var ids = [];
		var indexes = [];
		$scope.deleteIndex = true;
		$scope.pickList = function ($index, rows, modelname) {
			if (rows.checkId) {
				if (rows) {
					$scope.deleteIndex = false;
				}
				indexes.push($index);
				ids.push(rows.id) ;
			}else{
				var index = ids.indexOf(rows.id);
				ids.splice(index, 1);

				var index_item = indexes.indexOf($index);
				indexes.splice(index_item, 1);
				if (indexes.length === 0) {
					$scope.deleteIndex = true;
				}
			}
		};

		$scope.deleteList = function (modelname, Iterator) {
			restService.deleteList(indexes, ids, modelname, Iterator);
			ids = [];
			indexes = [];
		};




	}]);
