'use strict';
main.controller('budgetsController', ['$rootScope','$interval','registerService', 'budgetsService','growl','$stateParams', '$scope','$window', '$http', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state',
    function($rootScope,$interval,registerService, budgetsService,growl,$stateParams, $scope,$window, $http, appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state) {
        budgetsService.getBudgets().success(function(data, status) {
            $scope.departments = data.departments;
            $scope.years = data.years;
            $scope.users = data.users;
            $rootScope.role=data.role;
            $rootScope.Year = data.Year;
            $rootScope.userDept = data.userDept;

            $scope.budgets = restService.intiateNgTable(budgetsService.getBudgets(), $scope);
            $scope.searchedTerm = null;
            $scope.searchedTerm = {yearc: $rootScope.Year, dpt_name: $rootScope.userDept};
            $scope.budgets.reload();
            $rootScope.currentYear=new Date().getFullYear();
        });

        $scope.searchBudget = function(searchedBudget) {
            $scope.searchedTerm = null;
            if (searchedBudget.dpt_name == 'All Departments') {
                searchedBudget = undefined;
            }
            console.log(searchedBudget);
            $scope.searchedTerm = searchedBudget;
            $scope.budgets.reload();
        }

        $scope.downloadPDF = function(budgets,deptId,yearId) {
            budgetsService.getBudgetsPDF(budgets).success(function(data, status) {
                var url = 'index.php/FirePDF/?data=' +budgets +"&deptId="+deptId+"&yearId="+yearId;
                $window.open(url);
            });
        };

        $scope.FetchExcel = function(budgets,deptId,yearId) {
            budgetsService.getBudgetsEXCEL(budgets).success(function(data, status) {
                var url = 'index.php/FetchExcel/xls/?data='+budgets +"&deptId="+deptId+"&yearId="+yearId;;
                $window.open(url);
            });
        };


        $scope.invoiceCount = 0;
        $scope.priceTotal = 0;
        $scope.setTotals = function(budget) {
            if (budget) {
                $scope.invoiceCount += parseInt(budget.item_qty);
                $scope.priceTotal += parseInt(budget.price_total);
                

            }
            $state.reload();
            // $state.transitionTo($state.current, $stateParams, { reload: true, inherit: true, notify: true });
        }

        $scope.budgetStatuses = [{
            'text': 'Pending',
            'value': 'Pending'
        }, {
            'text': 'Cleared',
            'value': 'Cleared'
        }]

        $scope.budgetCurrencies = [{
            'text': 'USD',
            'value': 'USD'
        }, {
            'text': 'TZS',
            'value': 'TZS'
        }]

        //disply modalform.
        $scope.toggle = function(modalstate,budgId,modalId) {
            $scope.modalstate = modalstate;
            switch (modalstate) {
                case 'add':
                $scope.form_title = "Add New Budget";
                break;
            }
            $(modalId).modal('show');
        }
        
        

        //save new record & update existing record
        $scope.save = function(modalstate, id,action) {
            var url = "budgets";
            $http({
                method: 'POST',
                url: url,
                data: $.param($scope.budget),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).success(function(response) {
                console.log(action)
                if (action == 'close') {
                    location.reload();
                }else{
                    $scope.budget.currency ="";
                    $scope.budget.item_price ="";
                    $scope.budget.item_qty ="";
                    $scope.budget.exchangerate ="";
                    $scope.budget.remarks ="";
                    $scope.budget.added_date ="";
                    $scope.budget.item ="";
                    console.log($scope.budget)
                }
            }).error(function(response) {
                console.log(response);
                alert('An error has occured. Please check the log for details');
            });
        }

        $scope.showSuccess = function(success){
            growl.success('objective has been successfully added.');
        }
        $scope.edit = function(id) {
            if (modalstate === 'edit') {
                url += "/" + id;
            }
            dataFactory.httpRequest('budgets/' + id + '/edit').then(function(data) {
                console.log(data);
                $scope.form_title = data;
            });
        }
        $scope.saveEdit = function() {
            dataFactory.httpRequest('budgets/' + $scope.form_title.id, 'PUT', {}, $scope.form_title).then(function(data) {
                $(".modal").modal("hide");
                $scope.data = apiModifyTable($scope.data, data.id, data);
            });
        }
        $scope.deleteItem = function(id, modelname) {
            restService.deleteItem(id, modelname)
        }
        $scope.updateRow = function(id, modalname, data) {
            restService.updateRow(id, modalname, data);
        }
        // $scope.rates = {};
        //     $http.get('http://api.fixer.io/latest?base=ZAR')
        //         .then(function(res) {
        //             $scope.rates = res.data.rates;
        //             $scope.toType = $scope.rates.TZS;
        //             $scope.fromType = $scope.rates.USD;
        //             $scope.fromValue = 1;
        //             $scope.forExConvert();
        //         });
        //     $scope.forExConvert = function() {
        //         $scope.toValue = $scope.fromValue * ($scope.toType * (1 / $scope.fromType));
        //         $scope.toValue = $scope.toValue;
        //     };

        $scope.today = new Date();
        var tick = function() {
         $scope.clock = Date.now();
     }
     tick();
     $interval(tick, 1000);

     $scope.toggle = function(modalstate, deptId,modalId) {
        $scope.modalstate = modalstate;
        switch (modalstate) {
            case 'add':
            $scope.form_title = "Select Report To Export";
            break;
        }
        $(modalId).modal('show');
    }

}
]);