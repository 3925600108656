main.controller('HomeController', ['$rootScope','limitToFilter','$interval', '$scope','homeService','departmentsService','taskService', 'restService', '$modal', '$log', 'notify', '$filter', 

    function($rootScope,limitToFilter,$interval, $scope, homeService,departmentsService,taskService, restService, $modal, $log, notify, $filter) {
        'use strict';  
        $scope.Title = "Home";      
        $scope.module_name = `<i class="glyphicon glyphicon-home"></i> Home`;     

        $scope.home  = `<a href="#/home"><i class="glyphicon glyphicon-home"></i> Home </a>`;

        $scope.HomeDashboard = function () {  
            $scope.dash_url_status  = 'disabled';
            $scope.printableSection = "printHomeDashboard"; 
            $scope.crumb = $scope.url_label = '<i class="glyphicon glyphicon-home"></i> Dashboard';
        }

        $scope.searchObjective = function(searchedObjective){
            $scope.searchedTerm = null;
            if (searchedObjective.dpt_name == 'All Departments'){
                searchedObjective = undefined;
            }
            console.log(searchedObjective);
            $scope.searchedTerm = searchedObjective;
            $scope.objectives.reload();
        }

        $scope.options = {
            thickness: 50
        };
        homeService.fetchObjectives().success(function(data, objectives) {
            objectives=data.data;
            $scope.departments = data.departments;
            $scope.years = data.years;
            $scope.users = data.users;
            // $scope.objectives=data.data;
            // console.log(data.departments)
            $rootScope.userDept = data.userDept;
            
            // $scope.objectives = restService.intiateNgTable(homeService.fetchObjectives(), $scope);
            // $scope.searchedTerm = null;
            // $scope.searchedTerm = $rootScope.userDept;
            // $scope.objectives.reload();

            var totalPending = 0;
            var totalCompleted = 0;
            var totalProgress = 0;
            var totalNew = 0;
            var percentPending = 0;
            var percentCompleted = 0;
            var percentProgress = 0;
            var percentNew = 0;
            var total = 0;
            for (var i = 0; i < objectives.length; i++) {
                if (objectives[i].statId == 2) {
                    totalProgress++;
                }
                if (objectives[i].statId == 1) {
                    totalCompleted++;
                }
                if (objectives[i].statId == 3) {
                    totalPending++;
                }
                if (objectives[i].statId == 4) {
                    totalNew++;
                }
                total++;
            }
            percentPending = (total) ? parseFloat(totalPending / total * 100).toFixed(1) : 0;
            percentCompleted = (total) ? parseFloat(totalCompleted / total * 100).toFixed(1) : 0;
            percentProgress = (total) ? parseFloat(totalProgress / total * 100).toFixed(1) : 0;
            percentNew = (total) ? parseFloat(totalNew / total * 100).toFixed(1) : 0;
            // console.log(totalNew);

            if (total > 0) {
                $scope.Statuses = [{
                    label: "%Pending ",
                    value: percentPending,
                    suffix: "%",
                    color: "#ff0202"
                },
                {
                    label: "%Completed ",
                    value: percentCompleted,
                    suffix: "%",
                    color: "#00ff00"
                }, 
                {
                    label: "%New ",
                    value: percentNew,
                    suffix: "%",
                    color: "orange"
                }, 
                {
                    label: "%In progress",
                    value: percentProgress,
                    suffix: "%",
                    color: "rgb(0, 0, 255)"
                }]
            } else {
                $scope.Statuses = [];
            }

        });

        homeService.graphObjectives().success(function(data, objectives) {
            objectives=data.data;
            $scope.departments = data.departments;
            $scope.years = data.years;
            $scope.users = data.users;
            // $scope.objectives=data.data;
            // console.log(data.departments)
            $rootScope.userDept = data.userDept;
            
            // $scope.objectives = restService.intiateNgTable(homeService.fetchObjectives(), $scope);
            // $scope.searchedTerm = null;
            // $scope.searchedTerm = $rootScope.userDept;
            // $scope.objectives.reload();

            var totalPending = 0;
            var totalCompleted = 0;
            var totalProgress = 0;
            var totalNew = 0;
            var percentPending = 0;
            var percentCompleted = 0;
            var percentProgress = 0;
            var percentNew = 0;
            var total = 0;
            for (var i = 0; i < objectives.length; i++) {
                if (objectives[i].statId == 2) {
                    totalProgress++;
                }
                if (objectives[i].statId == 1) {
                    totalCompleted++;
                }
                if (objectives[i].statId == 3) {
                    totalPending++;
                }
                if (objectives[i].statId == 4) {
                    totalNew++;
                }
                total++;
            }
            percentPending = (total) ? parseFloat(totalPending / total * 100).toFixed(1) : 0;
            percentCompleted = (total) ? parseFloat(totalCompleted / total * 100).toFixed(1) : 0;
            percentProgress = (total) ? parseFloat(totalProgress / total * 100).toFixed(1) : 0;
            percentNew = (total) ? parseFloat(totalNew / total * 100).toFixed(1) : 0;
            // console.log(totalNew);
            $scope.colors = ['#04b172', '#04b172', '#04b172'];
            $scope.labels = ['Accounts', 'Admin', 'Cabledep', 'CSdep', 'Electricaldep', 'LAN dep', 'MoshiBranch','MwanzaBranch','NOC/Servers','Operational','Projects','Research','Sales','Software'];
            $scope.data = [
            [percentCompleted, percentPending, percentProgress, percentCompleted, percentPending, percentNew, percentProgress, percentPending, percentCompleted, percentProgress, percentCompleted, percentNew, percentCompleted, percentProgress],
            [percentPending, percentCompleted, percentNew, percentPending, percentNew, percentPending, percentCompleted, percentNew, percentPending, percentProgress, percentNew, percentCompleted, percentPending, percentProgress],
            [percentProgress, percentProgress, percentCompleted, percentProgress, percentCompleted,  percentNew,  percentProgress, percentPending, percentCompleted, percentProgress,  percentProgress, percentProgress, percentProgress, percentPending],
            [percentPending, percentCompleted, percentProgress, percentPending, percentNew,  percentProgress,  percentNew, percentCompleted, percentPending, percentCompleted,  percentNew, percentProgress, percentNew, percentCompleted]

            ];
            $scope.datasetOverride = [
            {
                label: "Bar chart",
                borderWidth: 1,
                type: 'bar'
            },
            {
                label: "Line chart",
                borderWidth: 3,
                hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(255,99,132,1)",
                type: 'line'
            }
            ];

        });



        $scope.today = new Date();
        var tick = function() {
           $scope.clock = Date.now();
       }
       tick();
       $interval(tick, 1000);
   }]); 