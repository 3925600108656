    'use strict';
  main.controller('fileController', ['$rootScope','$scope', '$http','Upload','$timeout', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams', '$filter', 'restService', '$state',
    function($rootScope, $scope, $http,Upload, $timeout, appFactory, $modal, $log, notify, modalService, ngTableParams, $filter, restService, $state) {


      $scope.submit = function(file) {
      if ($scope.form.file.$valid && file) {
        $scope.upload($scope.file);
        
      }
    };
    $scope.upload = function (file) {
        Upload.upload({
            url: 'upload',
            data: {file: file, 'username': $scope.username}
        }).then(function (resp) {
            console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
        }, function (resp) {
            console.log('Error status: ' + resp.status);
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
    };
    
    $scope.uploadFiles = function (files) {
      if (files && files.length) {
        for (var i = 0; i < files.length; i++) {
          Upload.upload({data: {file: files[i]}});
        }
       
        Upload.upload({data: {file: files}});
      }
    }
}]);