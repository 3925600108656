'use strict';

main.controller('userAccountController', ['$rootScope', '$scope', 'appFactory', '$modal', '$log', 'notify', 'modalService', 'ngTableParams','$filter', 
	
	function($rootScope, $scope, appFactory, $modal, $log, notify, modalService, ngTableParams,$filter) {

		$scope.Title    = "My account";   
		$scope.SubTitle = "My Account";   
		var modelname   = 'Transactioncode';

		$scope.Reports = function () {
			appFactory.getList(modelname).success(function(data, status) {   
				$scope.data = data.list; 
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

		
		










	}]); 